import abi from 'src/abi';
import { multicall } from 'src/utils/utils-multicall';
import BigNumber from 'bignumber.js';
import { request } from 'src/utils/utils-subgraph';
import { convertWeiToDec } from 'src/utils/utils-formats';
import { NFTS_DATA } from 'src/constants';
import { getNetworkProvider } from 'src/utils/utils-network';
import { stakingPoolContract } from 'src/utils/utils-contract';
import { isAddress } from 'ethers/lib/utils';
import { ethers } from 'ethers';
import config from '../config';

const PROD_BUNI_ADDRESS = '0x0e7beec376099429b85639eb3abe7cf22694ed49';
const PROD_WBNB_ADDRESS = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';

//hardcode get price buni in production
export const getTokenBuniPrice = async (): Promise<any> => {
  return { price: '0.0025' };
  const query = {
    tokenPrice: {
      __args: {
        id: PROD_BUNI_ADDRESS,
      },
    },
  };
  const res = await request('getTokenPrice', query);
  return res && res.tokenPrice;
};

//hardcode get price bnb in production
export const getTokenBnBPrice = async (): Promise<any> => {
  return { price: '660' };
  const query = {
    tokenPrice: {
      __args: {
        id: PROD_WBNB_ADDRESS,
      },
    },
  };
  const res = await request('getTokenPrice', query);
  return res && res.tokenPrice;
};

export const getTokenPrice = async (tokenId: string): Promise<string> => {
  if (!tokenId) {
    return '0';
  }
  const query = {
    tokenPrice: {
      __args: {
        id: tokenId.toLowerCase(),
      },
    },
  };
  const res = await request('getTokenPrice', query);
  return res && res.tokenPrice;
};

export const getStakeUnitPriceUsd = async (
  farmConfig: any,
): Promise<string | number> => {
  if (farmConfig.stakeType.toLowerCase() === 'token') {
    let res;
    if (farmConfig.poolTokens[0].symbol.toUpperCase() === 'WBNB') {
      res = await getTokenBnBPrice();
    } else if (farmConfig.poolTokens[0].symbol.toUpperCase() === 'BUNI') {
      res = await getTokenBuniPrice();
    } else {
      res = await getTokenPrice(
        farmConfig.poolTokens[0].contractAddress.toLowerCase(),
      );
    }
    //@ts-ignore
    return res && res.price;
  }
  const calls: any = [];
  //get balance of pool in token address
  farmConfig.poolTokens.forEach((poolToken: any) => {
    calls.push({
      address: poolToken.contractAddress,
      name: 'balanceOf',
      params: [farmConfig.stakingToken],
    });
  });
  //get token price
  const prices = {};
  for (let index = 0; index < farmConfig.poolTokens.length; index++) {
    let tokenPrice = {};
    if (farmConfig.poolTokens[index].symbol.toUpperCase() === 'WBNB') {
      tokenPrice = await getTokenBnBPrice();
    } else if (farmConfig.poolTokens[index].symbol.toUpperCase() === 'BUNI') {
      tokenPrice = await getTokenBuniPrice();
    } else {
      tokenPrice = await getTokenPrice(
        farmConfig.poolTokens[index].contractAddress,
      );
    }
    //@ts-ignore
    prices[farmConfig.poolTokens[index].contractAddress] = tokenPrice.price;
  }
  const result = await multicall(abi['Erc20'], calls);
  const totalLiq = result
    .reduce((total: any, tokenAmount: any, index: any) => {
      //@ts-ignore
      const unitPrice = prices[farmConfig.poolTokens[index].contractAddress];

      const totalToken = new BigNumber(unitPrice)
        .times(new BigNumber(tokenAmount.balance.toString()).toString())
        .div(1e18);

      total = new BigNumber(total).plus(totalToken);

      return total;
    }, new BigNumber(0))
    .toString();
  let [totalSupply] = await multicall(abi['Erc20'], [
    {
      address: farmConfig.stakingToken,
      name: 'totalSupply',
    },
  ]);
  totalSupply = new BigNumber(totalSupply.toString()).div(1e18);
  return new BigNumber(totalLiq).div(totalSupply).toString();
};

export const getNFTCard = (weiPendingReward: string) => {
  const pendingReward = Number(convertWeiToDec(weiPendingReward));
  if (pendingReward >= 10000) {
    return NFTS_DATA[4];
  }
  return (
    NFTS_DATA.find(
      (level) =>
        Number(level.lowestBuni) <= pendingReward &&
        pendingReward < level.highestBuni,
    ) || NFTS_DATA[0]
  );
};

export const getStakingPool = async (poolAddress: string) => {
  if (!poolAddress) {
    return null;
  }
  const calls = [
    {
      address: poolAddress,
      name: 'stakedToken',
      params: [],
    },
    {
      address: poolAddress,
      name: 'stakedTokenSupply',
      params: [],
    },
    {
      address: poolAddress,
      name: 'BASIS_POINT',
      params: [],
    },
  ];

  const [stakedToken, stakedTokenSupply, basePoint] = await multicall(
    abi['StakingPool'],
    calls,
    config.supportStakingNetwork,
  );

  return {
    lpToken: stakedToken, // allocData?.lpToken || 0,
    allocPoint: basePoint, //allocData?.allocPoint || 0,
    totalAllocPoint: basePoint,
    delayDuration: 0, // allocData?.delayDuration || 0,
    stakedTokenSupply: stakedTokenSupply.toString(),
  };
};

export const getUserStakingPool = async (
  userAddress: string,
  poolAddress: string,
) => {
  if (!isAddress(poolAddress) || !isAddress(userAddress)) {
    return null;
  }
  const calls = [
    {
      address: poolAddress,
      name: 'userInfo',
      params: [userAddress],
    },
    {
      address: poolAddress,
      name: 'pendingReward',
      params: [userAddress],
    },
  ];

  const [allocUserInfo, pendingReward] = await multicall(
    abi['StakingPool'],
    calls,
  );

  let stakedAmount = 0;
  if (allocUserInfo) {
    stakedAmount = allocUserInfo?.amount.toString() || '0';
  }

  return {
    poolAddress,
    stakedAmount,
    pendingReward: pendingReward.toString(),
  };
};

export const getPendingWithdrawal = async (
  poolAddress: string,
  userAddress: string,
) => {
  if (!isAddress(poolAddress) || !isAddress(userAddress)) {
    return null;
  }
  const contract = stakingPoolContract(
    poolAddress,
    getNetworkProvider(config.supportStakingNetwork),
  );
  const appointInfo = await contract.withdrawAppoints(userAddress);
  return {
    amount: appointInfo.amount.toString(),
    releaseTime: appointInfo.releaseTime.toString(),
  };
};

export const makeWithdrawParams = (poolAddress: string): Array<any> => {
  return [abi['StakingPool'], poolAddress, 'withdraw', [], {}];
};
export const makeStakeParams = (
  poolAddress: string,
  amount: string,
): Array<any> => {
  return [
    abi['StakingPool'],
    poolAddress,
    'deposit',
    [ethers.utils.parseEther(amount)],
    {},
  ];
};

type appointType = {
  requestId: string;
  amount: string;
  delayTime: string;
  signature: string;
};

export const makeAppointWithdrawParams = (
  poolAddress: string,
  appoint: appointType,
): Array<any> => {
  return [
    abi['StakingPool'],
    poolAddress,
    'appointWithdraw',
    [
      appoint?.requestId,
      appoint?.amount,
      appoint?.delayTime,
      appoint?.signature,
    ],
    {},
  ];
};

export const makeHarvestParams = (poolAddress: string): Array<any> => {
  return [abi['StakingPool'], poolAddress, 'harvest', [], {}];
};
